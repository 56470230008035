import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { LoginReducer } from "./auth/login/reducers";
import { PlatformReducer } from "./app/platform/reducer";
import { PlaygroundReducer } from "./app/playground/reducer";
import { AssistantsReducer } from "./app/assistants/reducer";
import { SignupReducer } from "./auth/signup/reducers";
import { VoiceLibraryReducer } from "./app/voice-library/reducer";
import { GetStartedReducer } from "./app/get-started/reducers";
import { OrganizationReducer } from "./app/organization/reducer";
import { LogsReducer } from "./app/logs/reducers";
import { HomeReducer } from "./app/home/reducers";
import { FilesReducer } from "./app/files/reducers";
import { FeedbackReducer } from "./app/feedback/reducers";
import { TeamReducer } from "./app/team/reducers";
import TeamsReducer from "./app/teams/reducers";
import TasksReducer from "./app/tasks/reducers";
import TaskRunsReducer from "./app/task_runs/reducers";
import IntegrationsReducer from "./app/integrations/reducer";

const store = configureStore({
    reducer: {
        auth: combineReducers({
            login: LoginReducer,
            signup: SignupReducer
        }),
        app: combineReducers({
            platform: PlatformReducer,
            playground: PlaygroundReducer,
            assistants: AssistantsReducer,
            voices: VoiceLibraryReducer,
            onboarding: GetStartedReducer,
            organizations: OrganizationReducer,
            logs: LogsReducer,
            home: HomeReducer,
            files: FilesReducer,
            feedback: FeedbackReducer,
            team: TeamReducer,
            teams: TeamsReducer,
            tasks: TasksReducer,
            task_runs: TaskRunsReducer,
            integrations: IntegrationsReducer
        })
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store }