import { axios, axiosv2 } from "../../../utils/axios.loader";
import { VapiAPIManager } from "../../../utils/vapi.manager";
import { AppDispatch } from "../../store";
import { setAgentAnalysisError, setAgentAnalysisLoading, setAgentAnalysisSuccess, setAgentCreateError, setAgentCreateLoading, setAgentCreateSuccess, setAgentGetLoading, setAgentGetSuccess, setAgentUpdateError, setAgentUpdateLoading, setAgentUpdateSuccess } from "./actions";

export const createAgent = (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(setAgentCreateLoading())
    try {
        const request = await axios.post(`/agent/create`, {
            ...payload,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setAgentCreateSuccess(response))
    } catch (err: any) {
        dispatch(setAgentCreateError(["object", "undefined"].includes(typeof(err?.response?.data?.detail)) ? "Something went wrong. Please contact the administrator" : err?.response?.data?.message))
    }
}


export const createV2Agent = (organization_id: string, payload: any) => async (dispatch: AppDispatch) => {
    dispatch(setAgentCreateLoading())
    try {
        const request = await axiosv2.post(`/agent/organization/${organization_id}/create`, {
            ...payload,
            organization_id: organization_id,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setAgentCreateSuccess(response))
    } catch (err: any) {
        dispatch(setAgentCreateError(["object", "undefined"].includes(typeof(err?.response?.data?.detail)) ? "Something went wrong. Please contact the administrator" : err?.response?.data?.message))
    }
}

export const updateAgent = (id: string, payload: any) => async (dispatch: AppDispatch) => {
    dispatch(setAgentUpdateLoading())
    try {
        const request = await axios.put(`/agent/update/${id}`, {
            ...payload,
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setAgentUpdateSuccess(response))
    } catch (err: any) {
        dispatch(setAgentUpdateError(err?.response?.data?.detail || err?.response?.data?.message))
    }
}

export const updateV2Agent = (organization_id: string, id: string, payload: any, callback?: () => void, errorCallback?: (err: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setAgentUpdateLoading())
    try {
        const request = await axiosv2.patch(`/agent/organization/${organization_id}/${id}`, {
            ...payload,
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setAgentUpdateSuccess(response))
        callback?.()
    } catch (err: any) {
        errorCallback?.(err?.response?.data?.detail || err?.response?.data?.message)
        dispatch(setAgentUpdateError(err?.response?.data?.detail || err?.response?.data?.message))
    }
}

export const uploadAgentImage = (organization_id: string, agent_id: string, blob: Blob | File | null, callback?: (data: any) => void) => async (dispatch: AppDispatch) => {
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.uploadProfilePic(agent_id, blob)
        callback?.(response)
    } catch (err) {
        callback?.({
            "status": "failed"
        })
    }
}


export const loadSpecificAgent = (agent: string, company?: string) => async (dispatch: AppDispatch) => {
    dispatch(setAgentGetLoading())
    try {
        const request = await axiosv2.get(`/agent/${agent}`)
        const response = request.data;
        dispatch(setAgentGetSuccess(response, {}))
    } catch (err) {
        console.log(err)
    }
}

export const testAgentViaCall = (name: string, phone_number: string, agent_phone_number: string, onSuccessCallback?: () => void) => async (dispatch: AppDispatch) => {
    try {
        await axios.post(`/agent/call_me_test`, {
            name,
            phone_number,
            agent_phone_number
        })
        onSuccessCallback?.()
    } catch (err) {
        console.log(err)
    }
}

export const testAgentViaCallV2 = (organization_id: string, assistantId: string, phoneNumber: string, number: string, name: string, onSuccessCallback?: () => void) => async (dispatch: AppDispatch) => {
    try {
        const manager = new VapiAPIManager(organization_id)
        await manager.initiateCall(assistantId, number, phoneNumber, name)
        onSuccessCallback?.()
    } catch (err) {
        console.log(err)
    }
}

export const attachPhoneNumber = async (organization_id: string, assistantId: string, phoneNumber: string) => {
    // todo: revisit for optimal logic
    try {
        const manager = new VapiAPIManager(organization_id)
        await manager.attachPhoneNumber(phoneNumber, assistantId)
    } catch (err) {
        console.log(err)
    }
}

export const searchPhoneNumbers = async (keyword = '', page = 1, size = 10) => {
    try {
        const request = await axios.get(`/agent/phone/my-numbers/?page=${page}&page_size=${size}${keyword ? `&filters=phone_number[in]=${keyword}` : ''}`)
        const numbers = request.data;
        return (numbers || []).map((v: any) => ({
            label: v.friendly_name,
            value: v.phone_number
        }))
    } catch (err) {
        return []
    }
}

export const getPhoneNumbers = async (organization_id: string, callback?: () => void) => {
    try {
        const manager = new VapiAPIManager(organization_id)
        const numbers = await manager.getPhoneNumbers()
        return (numbers || []).map((v: any) => ({
            label: `${v.number} ${v.assistantId ? `(In use)` : ''}`,
            value: v.id
        }))
    } catch (err) {
        return []
    }
}

export const getFiles = async (organization_id: string, callback?: () => void) => {
    try {
        const manager = new VapiAPIManager(organization_id)
        const numbers = await manager.getFiles()
        return (numbers || []).map((v: any) => ({
            label: v.name,
            value: v.id,
            
        }))
    } catch (err) {
        return []
    }
}

export const getAgentAnalysis = (agent_id: string, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setAgentAnalysisLoading())
    try {
        const { data: intents } = await axios.get(`/interactions/analysis/assistants/${agent_id}/query-builder?group_by=extraction->sentiment`)
        const { data: questions } = await axios.get(`/interactions/analysis/assistants/${agent_id}/query-builder?group_by=extraction->questions[]`)
        const { data: words } = await axios.get(`/interactions/analysis/assistants/${agent_id}/query-builder?group_by=extraction->words[]`)
        const { data: history } = await axios.get(`/interactions/analysis/assistants/${agent_id}/query-builder?group_by=created_at:YYYY-MM-DD`)
        const { data: ended } = await axios.get(`/interactions/assistants/${agent_id}/query-builder?group_by=state`)
        const { data: sentiment } = await axios.get(`/interactions/analysis/assistants/${agent_id}/query-builder?group_by=evaluation->answer`)
        const intent_bucket: {
            [key: string]: number
        } = {}
        for (const intent of intents.buckets) {
            intent_bucket[intent.key_name] = intent.docs_count
        }
        const questions_bucket: {
            [key: string]: number
        } = {}
        for (const intent of questions.buckets) {
            questions_bucket[intent.key_name] = intent.docs_count
        }
        const words_bucket: {
            [key: string]: number
        } = {}
        for (const intent of words.buckets) {
            words_bucket[intent.key_name] = intent.docs_count
        }
        const state_bucket: {
            [key: string]: number
        } = {}
        for (const intent of ended.buckets) {
            state_bucket[intent.key_name] = intent.docs_count
        }
        const sentiment_bucket: {
            [key: string]: number
        } = {}
        for (const intent of sentiment.buckets) {
            let sentiment
            let score = Number.parseInt(`${intent.key_name || 0}`, 10)
            if (score >= 6) {
                sentiment = "positive"
            } else if (score >= 3) {
                sentiment = "neutral"
            } else {
                sentiment = "negative"
            }
            if (!sentiment_bucket[sentiment]) {
                sentiment_bucket[sentiment] = 0
            }
            sentiment_bucket[sentiment] += intent.docs_count
        }
        dispatch(setAgentAnalysisSuccess({
            intents: {
                ...intent_bucket,
                total: Object.values(intent_bucket || {}).reduce((v: any, c: any) => v + c, 0)
            },
            questions: questions_bucket,
            words: words_bucket,
            history: history.buckets,
            sentiment: {
                ...sentiment_bucket,
                total: Object.values(sentiment_bucket || {}).reduce((v: any, c: any) => v + c, 0)
            },
            status: {
                ...state_bucket,
                total: Object.values(state_bucket || {}).reduce((v: any, c: any) => v + c, 0)
            }
        }))
    } catch (err) {
        dispatch(setAgentAnalysisError("Failed to fetch analytics"))
    }
}