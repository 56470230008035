import {
  Avatar,
  Box,
  Flex,
  Grid,
  Link,
  Stack,
  Button as CoreButton,
  Text,
  useDisclosure,
  useToast,
  Divider,
  Tooltip,
  Modal,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Heading,
  IconButton,
  DrawerBody,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  ModalOverlay,
  ModalFooter,
  DrawerFooter,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import { TabItem } from "./SpecificAssistant";
import { ChevronsUpDownIcon, LockIcon, Pencil, PencilIcon, PlugZapIcon, SquareArrowOutUpRightIcon, XIcon } from "lucide-react";
import { Field, Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { updateOrganization } from "../../lib/app/organization/thunk";
import { useEffect, useState } from "react";
import {
  addMemberTeam,
  getTeam,
  removeMemberTeam,
  updateMemberTeam,
} from "../../lib/app/team/thunk";
import _ from "lodash";
import { Select, chakraComponents } from "chakra-react-select";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import CrispForm from "./CrispForm";
import { listIntegrations } from "~/lib/app/integrations/thunk";
import { useParams } from "react-router-dom";
import HubSpot from "./Hubspot";
import ApnaForm from "./ApnaForm";

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

export default function Integrations() {
  const { organization } = useParams()
  const _organization = useAppSelector((state) => state.app.organizations.get);
  const _organizationUpdated = useAppSelector(
    (state) => state.app.organizations.update
  );
  const profile = useAppSelector((state) => state.app.platform.profile);
  const connectedIntegrations = useAppSelector((state) => state.app.integrations.list);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [currentMember, setCurrentMember] = useState<any | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [selectedIntegration, setSelectedIntegration] = useState<any | undefined>({})
  const [index, setIndex] = useState(0)
  const integrations = [
    {
      name: "Crisp",
      image: "https://crisp.chat/favicon-512x512.png",
      website: "https://crisp.chat/",
      description:
        "Give your customer experience a human touch",
      active: false,
      provider: "CRISP",
      form: (onClose: () => void) => <CrispForm config={selectedIntegration} onClose={onClose}/>
    },
    {
      name: "Apna",
      category: "ATS",
      provider: "APNA",
      image: "https://apna.co/career-central/wp-content/uploads/2023/11/apna-logo.png",
      website: "https://apna.co",
      description:
        "Connect Apna account to manage your candidates",
      active: true,
      form: (onClose: () => void) => <ApnaForm config={selectedIntegration} onClose={onClose}/>
    },
    {
      name: "HubSpot",
      category: "CRM",
      provider: "HUBSPOT",
      image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/168_Hubspot_logo_logos-512.png",
      website: "https://hubspot.com",
      description:
        "Connect Hubspot account to manage contacts across your CRM",
      active: true,
      form: (onClose: () => void) => <HubSpot config={selectedIntegration} onClose={onClose}/>
    },
    {
      name: "Cal",
      image: "https://cal.com/android-chrome-512x512.png",
      website: "https://cal.com",
      description:
        "Connect Cal.com account to build processes around your events",
      active: false
    },
  ];

  useEffect(() => {
    dispatch(listIntegrations(organization!))
  }, [organization])

  return (
    <Page
      subtitle="Discover & Connect ESL to other tools that your team uses."
      title="Integrations"
    >
      <Modal size={"lg"} isCentered isOpen={isModalOpen} onClose={onModalClose}>
      <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Connect Account</Text>
          </ModalHeader>
          <ModalBody>
            {integrations[index].form?.(() => {
            setSelectedIntegration({})
            onModalClose()
            dispatch(listIntegrations(organization!))
          })}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Drawer size={"lg"} isOpen={isOpen} onClose={() => {
        setSelectedIntegration({})
        onClose()
      }}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Heading size={"lg"}>Connected Accounts</Heading>
            <IconButton aria-label="" icon={<XIcon size={20} />} variant={"none"} size={"sm"} onClick={() => {
              onClose()
            }} />
          </DrawerHeader>
          <DrawerBody>
            <Stack gap={4}>
              {connectedIntegrations.data.filter((d: any) => d.provider === integrations[index].provider).map((i: any) => {
                return (
                  <>
                  <Stack gap={1}>
                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontWeight={"semibold"}>{i.name}</Text>
                    <Flex alignItems={"center"} gap={2}>
                    <Badge variant={"green-subtle"}>Active</Badge>
                    <IconButton variant={"outline"} size={"sm"} rounded={"full"} aria-label="" icon={<PencilIcon size={16} />} onClick={() => {
                      // let hasIntegration = connectedIntegrations.data.filter((i: any) => i.provider === integrations[index].provider)[0]
                      setSelectedIntegration(i)
                      onModalOpen()
                    }} />

                    </Flex>
                    
                    </Flex>
                    <Text fontSize={"sm"} className="text-muted-foreground">{i.id}</Text>
                  </Stack>
                  <Divider />
                  </>
                )
              })}
            </Stack>
            {!(connectedIntegrations.data.filter((d: any) => d.provider === integrations[index].provider).length && connectedIntegrations.state === "success") ? 
            <Center h={"70vh"}>
              <Stack alignItems={"center"} textAlign={"center"}>
                <PlugZapIcon />
                <Text mb={2}>No connected accounts found</Text>
                <Button variant={"dark"} onClick={() => {
                  setSelectedIntegration({})
                  onModalOpen()
                }}>Add account</Button>
              </Stack>
            </Center> : <Text></Text>}
            {/* <CrispForm onClose={() => {
            setSelectedIntegration({})
            onClose()
          }} config={selectedIntegration} /> */}
          </DrawerBody>
          <DrawerFooter>
           {(connectedIntegrations.data.filter((d: any) => d.provider === integrations[index].provider).length && connectedIntegrations.state === "success") ? <Button onClick={() => {
              setSelectedIntegration({})
              onModalOpen()
            }} variant={"dark"}>Connect new account</Button> : <></>}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Divider mt={3} />
      <Stack px={3} mt={4}>
        <Grid gridTemplateColumns={"repeat(3, 1fr)"} gap={4}>
          {integrations.map((integration, index) => {
            let hasIntegration = connectedIntegrations.data.filter((i: any) => i.provider === integration.provider)[0]
            return (
              <Stack
                justifyContent={"space-between"}
                rounded={"xl"}
                className="border"
              >
                <Flex
                  flex={1}
                  justifyContent={"space-between"}
                  className="border-b"
                >
                  <Stack py={3} px={3} gap={3}>
                    <Box>
                      <Flex gap={1} alignItems={"center"}>
                        <Box>
                          <Text fontSize={"lg"} fontWeight={"semibold"}>
                            {integration.name}
                          </Text>
                          <Link
                            gap={2}
                            display={"flex"}
                            alignItems={"center"}
                            href={integration.website}
                            target="_blank"
                            className="!underline text-muted-foreground"
                            fontSize={"sm"}
                          >
                            {new URL(integration.website).hostname}{" "}
                            <SquareArrowOutUpRightIcon size={14} />
                          </Link>
                        </Box>
                      </Flex>
                      <Text
                        mt={2}
                        className="text-muted-foreground"
                        fontSize={"sm"}
                      >
                        {integration.description}
                      </Text>
                    </Box>
                  </Stack>
                  <Box pos={"relative"}>
                    <Box></Box>
                    <Avatar
                      rounded={"xs"}
                      size={"lg"}
                      top={-0.5}
                      right={-0.5}
                      borderRadius={0}
                      src={integration.image}
                    />
                    {/* <Badge className="whitespace-nowrap" variant={"green-subtle"}>
                    Coming Soon
                  </Badge> */}
                  </Box>
                </Flex>
                <Flex justifyContent={"space-between"} px={2} py={2} pt={0}>
                  <CoreButton  fontSize={"xs"} variant={"outline"} size={"sm"} fontWeight={"medium"}>View Integration</CoreButton>
                  <Tooltip hasArrow label={!integration.form ? "Coming soon" : ""}>
                  <CoreButton  isDisabled={!integration.form} onClick={integration.form ? () => {
                    setIndex(index)
                    onOpen()
                  } : undefined} size={"sm"} fontSize={"xs"} colorScheme={!hasIntegration ? "black" : "green" } className={!hasIntegration ? "bg-black" : ""} leftIcon={!hasIntegration ? <LockIcon size={16} /> : <Pencil size={16} />}>{hasIntegration ? "Edit Integration" : "Connect Now"}</CoreButton>
                  </Tooltip>
                </Flex>
              </Stack>
            );
          })}
        </Grid>
      </Stack>
    </Page>
  );
}
