import { AxiosInstance } from "axios"
import { axiosv2, axios } from "./axios.loader"

export class VapiAPIManager {
    private organization: string
    private axios: AxiosInstance
    private axiosv1: AxiosInstance

    constructor (organization: string) {
        if (!organization) {
            const error = new Error("Error initializing api manager. Invalid organization id")
            throw error
        }
        this.organization = organization
        this.axiosv1 = axios
        this.axios = axiosv2
    }

    async getCallLogs(assistant?: string, organization?: string, page: number = 1, size: number = 25) {
        const params = new URLSearchParams({
            ...(assistant ? { assistant_id: assistant } : {})
        })
        const request = await this.axiosv1.get(`/interactions/${assistant ? "assistants" : "organizations"}/${organization || assistant}?page=${page}&page_size=${size}&sort_value=-created_at`)
        const response = request.data
        return response
    }

    async getSpecificCallLog(call: string, assistant_id?: string) {
        const request = await this.axiosv1.get(`/interactions/${call}`)
        const response = request.data
        return response
    }

    async getMetrics(start: string, end = new Date().toISOString()) {
        const request = await this.axios.get(`/agent/organization/${this.organization}/metrics?start_date=${start}&end_date=${end}`)
        const response = request.data
        return response
    }

    async getAnalytics(queries: any[]) {
        const request = await this.axios.post(`/agent/organization/${this.organization}/analytics`, {
            queries
        })
        const response = request.data
        return response
    }

    async getAssistantAnalytics(queries: any[]) {
        const request = await this.axios.post(`/agent/organization/${this.organization}/analytics`, {
            queries
        })
        const response = request.data
        return response
    }

    async getAssistants() {
        const request = await this.axios.get(`/agent/organization/${this.organization}/provider-agents`)
        const response = request.data
        return response
    }

    async getFiles() {
        const request = await this.axios.get(`/agent/organization/${this.organization}/files`)
        const response = request.data
        return response
    }

    async createFile(file: Blob | File | null) {
        const form = new FormData();
        form.append("file", file!)
        const request = await this.axios.post(`/agent/organization/${this.organization}/files`, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        const response = request.data
        return response
    }

    async uploadProfilePic(agent_id: string, file: Blob | File | null) {
        const form = new FormData();
        form.append("file", file!)
        const request = await this.axios.post(`/agent/${agent_id}/upload-image`, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        const response = request.data
        return response
    }

    async getFile(id: string) {
        const request = await this.axios.get(`/agent/organization/${this.organization}/files/${id}`)
        const response = request.data
        return response
    }

    async initiateCall(assistantId: string, number: string, phoneNumber: string, name: string) {
        //todo: revisit with actual agent id, rather than provider id
        const request = await this.axios.post(`/agent/organization/${this.organization}/${assistantId}/initiate-call`, {
            phoneNumberId: phoneNumber,
            number,
            name
        })
        const response = await request.data
        return response
    }

    async attachPhoneNumber(id: string, assistantId: string) {
        //todo: revisit with actual agent id, rather than provider id
        const request = await this.axios.patch(`/agent/organization/${this.organization}/phone-numbers/${id}`, {
            assistantId
        })
        const response = await request.data
        return response
    }

    async getPhoneNumbers() {
        const request = await this.axios.get(`/agent/organization/${this.organization}/phone-numbers`)
        const response = request.data
        return response
    }
}